import React, { FC } from 'react';
import { Link } from 'gatsby';

import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsProductsList } from './models';

import './ProductsList.scss';

const ProductsList: FC<IPropsProductsList> = ({ productsList, ctaCardTitle }) => (
  <div data-testid="ProductsList" className="products-list">
    <div className="products-list__container">
      {productsList?.nodes.length
        ? productsList.nodes.map(({ title, url, imagePreview }) => (
            <article key={title} className="products-list__item">
              <Link to={url} className="products-list__item-link">
                <div className="products-list__item-image">
                  <GatsbyImage image={imagePreview} objectFit="contain" />
                </div>

                <div className="products-list__item-title">{title}</div>
                {ctaCardTitle ? (
                  <div className="products-list__item-btn">
                    <span className="fake-button">{ctaCardTitle}</span>
                  </div>
                ) : null}
              </Link>
            </article>
          ))
        : null}
    </div>
  </div>
);
export default ProductsList;

import React, { FC } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsFiltersList } from './models';

import './FiltersList.scss';

const FiltersList: FC<IPropsFiltersList> = ({ filtersList }) => {
  const location = useLocation();

  return (
    <div data-testid="FiltersList" className="filters-list">
      <h2 className="filters-list__title">{filtersList.title}</h2>
      {filtersList?.items?.length ? (
        <div className="filters-list__items">
          {filtersList.items.map(({ title, image, imageActive, link }) => (
            <div
              key={title}
              className={classNames('filters-list__item', {
                current: link[0].url === location.pathname,
              })}
            >
              <Link to={link[0].url} className="filters-list__item-link">
                <div className="filters-list__item-image">
                  <GatsbyImage
                    image={image}
                    className="filters-list__item-image--default"
                    objectFit="contain"
                  />
                  {imageActive ? (
                    <GatsbyImage
                      image={imageActive}
                      className="filters-list__item-image--active"
                      objectFit="contain"
                    />
                  ) : null}
                </div>

                <div className="filters-list__item-title">{title}</div>
              </Link>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
export default FiltersList;

import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import PageIntro from 'components/elements/PageIntro';
import FiltersList from 'components/sections/FiltersList';
import ProductsList from 'components/sections/ProductsList';

import { IPropsProductListingPage } from './models';

import './ProductListingPage.scss';

const ProductListingPage: FC<IPropsProductListingPage> = ({
  data: { pageData, productsByCategoryTag, plpSettings },
  pageContext,
}) => {
  const { seo, urls, title } = pageData;
  const { breadcrumbs, lang } = pageContext;

  return (
    <Layout
      data-testid="ProductListingPage"
      seo={seo}
      lang={lang}
      urls={urls}
      className="product-listing-page"
    >
      <PageIntro title={title} breadcrumbs={breadcrumbs} />
      <Container>
        <FiltersList filtersList={plpSettings} />
        <ProductsList
          productsList={productsByCategoryTag}
          ctaCardTitle={plpSettings?.ctaCardTitle}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!, $productCategoryTags: [Int]) {
    pageData: productListingPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentProductListingPage
    }
    productsByCategoryTag: allProductPage(
      filter: { tags: { elemMatch: { id: { in: $productCategoryTags } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    plpSettings: plpSettingsType(lang: { eq: $lang }) {
      ...FragmentPlpSettings
    }
  }
`;

export default ProductListingPage;
